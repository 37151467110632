import React from "react";
import styled from "styled-components";

const CaremindInfoBanner: React.FC = () => {
  return <CaremindInfoBannerLayout>슬라이드 1</CaremindInfoBannerLayout>;
};

export default CaremindInfoBanner;

const CaremindInfoBannerLayout = styled.div`
  width: 100%;
  height: 100%;
`;
